
// THIS IS THE CRENESSITY LANDING PAGE


#crenessity-landing {
    padding-top: 0;
    text-align: center;
    .rich-text {
        p {
            font-weight: 500;
        }
    }

    .static-bg-image.mobile {
        background-color: $gray-light;
    }

    @media (min-width: $desktop) {
        padding-top: 60px;
        text-align: left;

        .static-bg-image.desktop {
            position: absolute;
            right: 0;
            left: initial;
            top: 20px;
            bottom: 0;
            width: 31%;
            img {
                height: 100% !important;
                width: 100% !important;
                object-fit: cover;
                object-position: left top;
            }
        }
    }
}
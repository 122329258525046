#access-simplified {
    .static-bg-image.desktop {
        width: 50%;
        height: 100%;
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;

        .bg-image {
            object-position: right bottom;
        }
    }
}
.btn {
    font-weight: 500;
    line-height: calc(35/25);
    color: $gray-dark;
    background: $white;
    text-align: center;
    display: inline-block;

    &.btn-small {
        @include rem(font-size, 22);
        border-radius: 25px;
        line-height: calc(26/22);
        padding: 20px;

        @media (min-width: $tablet) {
            @include rem(font-size, 18);
            line-height: calc(30/18);
        }
    }

    &.btn-medium {
        border-radius: 25px;
        @include rem(font-size, 25);
        line-height: calc(35/25);
        padding: 20px;
    }

    &.btn-large {
        @include rem(max-width, 525);
        @include rem(font-size, 25);
        border-radius: 100px;

        line-height: calc(35/25);
        padding: 15px;
        width: 100%;

        @media (min-width: $tablet) {
            @include rem(font-size, 28);
            border-radius: 100px;
            line-height: calc(35/28);
            padding: 25px 50px;

        }
    }

    &:hover, &:focus {
        // color white
        // fill background with same color of box-shadow from the medication theme
    }
}
#financial-support-icons {
    .icon-wrapper {
        flex-basis: 7%;

        img {
            max-width: 103px !important;
        }
    }

    .icon-text-block img {
        max-width: 103px;
    }
    
    .items .item+.item img {
        max-width: 72px !important;
        
    }
    
    .items .item+.item+.item img {
        max-width: 142px !important;
    }

    .column-container .fineprint-box-block {
        margin: auto;
    }
}

#copay-card h3,
#savings-program h3,
#copay-savings-card h3,
#quickstart h3,
#quickstart-program h3,
#pas h3,
#pap h3 {
    font-size: 25px;
    font-weight: 500;
    color: $gray-dark;
    border: #B295C6 solid 1px;
    border-radius: 25px;
    padding: 35px 37px;
    background: $white;

    @media (max-width: $tablet) {
        text-align: center;
    }
}

#copay-card,
#copay-savings-card,
#savings-program,
#pas,
#pap {
    background: linear-gradient(0deg, $gray-light 50%, $white 50%);
}

#quickstart-program,
#quickstart {
    background: linear-gradient(0deg, $white 50%, $gray-light 50%);
}

.block-one_column:has(#copay-card)+.block-two_columns,
.block-one_column:has(#copay-savings-card)+.block-two_columns,
.block-one_column:has(#savings-program)+.block-two_columns,
.block-one_column:has(#quickstart)+.block-two_columns, 
.block-one_column:has(#quickstart-program)+.block-two_columns, 
.block-one_column:has(#pas)+.block-one_column,
.block-one_column:has(#pap)+.block-one_column {
    .bg-gray-light.text-white {
        color: $gray-dark;
    }
}

.financial-support {
    #copay-card-headers,
    #copay-savings-card-headers,
    #savings-program-headers,
    #quickstart-headers,
    #quickstart-program-headers,
    #pas-headers,
    #pap-headers {
        h2 {
            font-size: 50px;
            line-height: 55px;
        }
    }

    h3 {
        font-size: 30px;
        line-height: 46px;
    }

    #pas-content,
    #pap-content {
        .bg-gray-light.static-bg-image.desktop {
            background-color: $gray-light;
        }
    
        .static-bg-image.desktop {
    
            @media (max-width: 2100px) {
                left: auto;
                bottom: 0;
                right: 0;
                text-align: right;
            }
    
            img {
                object-position: right bottom;

                @media (max-width: 2100px) {
                    width: 40%;
                }
            }
        }
    }
}

.text-white#pap-content,
.text-white#pas-content {
    color: $gray-dark;
}

.bg-gray-light#pas-content,
.bg-gray-light#pap-content {
    background-color: transparent;

    @media (max-width: $desktop) {
        background-color: $gray-light;
    }

    .static-bg-image.desktop {
        background-color: $gray-light;
    }
}


.hero-interior-block {
    position: relative;
    text-align: center;
    padding: 20px 0 45px 0;

    .logo {
        width: 156px;
        margin: 0 auto 1.5rem auto;
    }

    .hero-image {
        width: 100%;
        margin-bottom: 1.2rem;
        img.show-tablet-down {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .rich-text {
        h1 {
            @extend .h2;
        }
    }

    p {
        margin-top: .4rem;
        a {
            color: $purple;
        }
    }

    .btn {
        margin-top: 36px;
    }

    @media (min-width: $tablet) {
        .hero-image {
            img {
                &.show-tablet-up {
                    width: 100%;
                    height: auto;
                }
                &.show-tablet-down {
                    display: none;
                }
            }
        }
    }
    
    @media (min-width: $desktop) {
        padding: 90px 0 70px 0;
        text-align: left;

        .hero-image {
            position: absolute;
            left: 63.8%;
            top: 0;
            width: 42.5%;
            height: 100%;
            
            img.show-tablet-up {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: left bottom;
            }
        }

        .copy  {
            width: 60%;
            max-width: 1110px;
        }

        .btn {
            margin-top: 60px;
        }
    }
}
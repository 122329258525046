.callout-card {
    border-radius: 25px;
    overflow: hidden;

    .callout-card-inner {
        padding: 16px;
    }

    .logo {
        display: block;
        max-width: 209px;
        height: auto;
        margin: 0 auto 40px;

        @media (max-width: $desktop) {
            margin: 20px auto;
        }
    }

    .buttons {
        display: flex;
        flex-flow: column;
        gap: 16px;
    }

    .btn {
        width: 100%;
    }

    @media (min-width: $tablet) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media (min-width: $desktop) {
        .callout-card-inner {
            padding-right: 40px;
            display: flex;
            justify-content: space-between;
        }

        .logo {
            margin: 0 16px;
            max-width: 237px;
            align-self: center;
        }

        .buttons {
            min-width: 200px;
            flex-shrink: 0;
            width: 56%;
            padding-left: 18px;
            margin-left: auto;
        }

        &.crenessity {
            .logo {
                max-width: 207px;
                margin-bottom: 0;
            }
        }

        &.ingrezza {
            .callout-card-inner {
                padding-top: 18px;
            }
        }
    }
}

.callout-cards-block {
    padding: 1.8rem 0 3rem;

    .grid {
        grid-gap: 45px 50px;
        max-width: 1625px;
        margin: 0 auto;

        @media (min-width: 1200px) {
            grid-template-columns: 40% 1fr;
        }
    }

    h2 {
        margin-bottom: 2rem;
    }

    @media (min-width: $tablet) {
        padding-top: 1.5rem;
    }

    @media (min-width: $desktop) {

        h2 {
            margin-bottom: 1rem;
        }
    }
}

.exit-modal-container {
    display: none;
    
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;

    justify-content: center;

    .exit-modal {
        border-radius: 25px;
        border: #FDB515 solid 3px;
        background-color: $white;
        max-width: 1121px;
        margin: 36px;
        padding: 0 28px 100px 28px;
        position: relative;
        z-index: 9;
        
        flex-flow: column;
        justify-content: space-between;
        align-self: center;
    }

    .modal-content {
        font-size: 34px;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        p {
            color: #0F3050;
            flex-basis: 100%;
            margin-bottom: 30px;
        }

        .modal-link {
            color: $purple;
            text-decoration: underline;
        
            &.btn {
                color: $white;
                background-color: $purple;
                padding: 20px 50px;
                border-radius: 17px;
                text-decoration: none;
                
                @media (max-width: $tablet) {
                    margin-top: 45px;
                }
            }
        }
    }

    .modal-close {
        margin: 35px 15px auto auto;
        flex-basis: 50px;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .7;
        background-color: #000;
    }

}
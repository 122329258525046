body.help-in-one-place,
body.hands-on-support {
    #cc-h3 .inner {
        font-size: 3.125rem;
    }

    #crenessity-resources {

        .grid {
            gap: 36px;
        }
    
        .rich-text b, .icon-text-block b {
            font-weight: 700 !important;
        }
    
        .icon-text-block {
            img {
                max-height: 100px;
                width: auto;
            }
        }
    
        @media (min-width: $desktop) {
            .icon-wrapper {
                width: 5%;
                margin-left: 50px;
            }

            .grid {
                gap: 0;
            }
        }
    }

    #care-coordinator ul li {
        font-size: 24px;

        @media (max-width: $tablet) {
            font-size: 20px;
        }
    }

}

#cc-h3 .inner h3 {
    font-size: 3.125rem;
}


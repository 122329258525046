$cols: (
    'block-two_columns': 2,
    'block-three_columns': 3,
    'block-four_columns': 4
);

@each $key, $val in $cols {
    .#{$key} .grid {
        @extend .grid-#{$val};
    }
}


.block-column {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    position: relative;

    img {
        display: inline-block;
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    .static-bg-image.mobile {
        margin-bottom: 30px;

        img.show-tablet-down {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    @media (min-width: $tablet) {
        padding-top: 2rem;
        padding-bottom: 2rem;

        .static-bg-image {
            img {

                &.show-tablet-up {
                    width: 100%;
                    height: auto;
                }

                &.show-tablet-down {
                    display: none;
                }
            }
        }
    }

    @media (max-width: $desktop) {
        .static-bg-image.desktop {
            display: none;
        }
    }

    @media (min-width: $desktop) {
        padding-top: 2rem;
        padding-bottom: 2rem;

        .static-bg-image.mobile {
            display: none;
        }

        .static-bg-image.desktop {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        
        &.bg-left-top {
            .static-bg-image img {object-position: left top;}
        }

        &.bg-center {
            .static-bg-image img {object-position: center;}
        }

        &.bg-right-top {
            .static-bg-image img {object-position: right top;}
        }
    }
}

.block-two_columns {
    @media (max-width: $desktop) {
        .reverse-col-mobile {
            .column-container {
                &:first-child {
                    order: 2;
                }

                &:last-child {
                    order: 1;
                }
            }
        }
    }

    @media (min-width: $desktop) {
        .custom-grid {
            .grid {
                $colPercentage: var(--left-col-width);
                grid-template-columns: $colPercentage 1fr !important;
            }
        }

        .grid {
            gap: 75px;
        }
    }
}
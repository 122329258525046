.block-column:has(#crenessity-isi) {
    padding-left: 25px !important;
    padding-right: 10px !important;
    max-width: calc(1846px + 46px);

    .rich-text {
        max-height: 600px;
        overflow-y: scroll;
        overflow-x: hidden;
        .inner {
            @extend .container;
            padding-left: 0 !important;
            padding-right: 10px;
        }
    }

         /* width */
         ::-webkit-scrollbar {
            width: 15px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
            border: 1px solid black;
            border-radius: 0;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #D5D0D0;
            width: 30px;
            height: 30px;
        }


    @media (min-width: $desktop) {
        padding-left: 0 !important;
        padding-right: 46px !important;
        .rich-text {
            
            .inner {
                padding-left: 40px !important;
            }
        }
         ::-webkit-scrollbar {
            width: 30px;
        }

    }
}

#crenessity-hero {
    text-align: center;

    .rich-text {
        h1 {
            @extend .h2;
        }
    }
    p {
        margin-top: .4rem;
        a {
            color: $purple;
        }
    }

    @media (min-width: $desktop) {
        padding: 90px 0 70px 0;
        text-align: left;
        background-position: cover;

        .static-bg-image {
            left: 63.8%;
            height: 100%;
            max-width: 816px;
            img {
                object-position: left bottom;
            }
        }
    }

    .fineprint-box-block {
        @media (max-width: $desktop) {
            margin: auto;
        }
    }
}


.call-to-action-block {
    @media (min-width: $tablet) {
        .rich-text {
            br {
                display: none;
            }
        }

        .font-30, .font-32 {
            display: inline;
        }
    }
}
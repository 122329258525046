body.patient {

    #crenessity-hero {
        overflow: hidden;
        position: relative;
        #overview {
            p:last-child {
                max-width: 92%;
            }
        }

        .fineprint-box-block {
            padding: 15px 10px;
        }

        @media (min-width: $tablet) {
            padding-top: 40px;
            .fineprint-box-block {
                padding: 9px 30px;
                width: 80%;

                .text-navy  {
                    font-weight: 500;
                }
                b .text-navy, b.text-navy{
                    font-weight: 700 !important;
                }
            }

            .btn-wrapper + .rich-text, .spacer + .rich-text {
                max-width: 86%;

                a {
                    color: $gray-dark;
                    text-decoration: underline;
                }
            }
        }

        @media (min-width: $desktop) {
            .static-bg-image {
                left: 58.8%;
                width: calc(100% - 58.8%) !important;
                top: 0;
                height: 100%;
                max-width: unset;
                img {
                    object-fit: right center;
                }
            }
        }

        @media (max-width: $tablet) {
            .btn-wrapper a br {
                display: none;
            }
        }
    }

    #crenessity-indicates {
        .inner {
            max-width: 80%;
            @media (max-width: $desktop) {
                max-width: 100%;
            }
        }
    }
}
body {
  @media (max-width: $desktop) {
    &:has(.header-wrapper.menu-open) {
      overflow: hidden;
    }
  }
}

header {
  @include rem(font-size, 28);
  color: $gray-dark2;

  @media (min-width: 1025px) {
    #nav {
      display: block !important;
      padding-left: 50px;
    }
  }

  @media (max-width: $desktop) {
    @include rem(font-size, 24);
    background-color: $white;

    &:has(.header-wrapper.menu-open) {
      position: absolute;
      height: 100%;
      width: 100%;
      overflow: scroll;
      z-index: 9;
    }
  }

  @media (max-width: 1700px) {
    @include rem(font-size, 23);
  }
  
  @media (max-width: 1540px) {
    @include rem(font-size, 19);
  }
  
  @media (max-width: 1339px) {
    @include rem(font-size, 16);
  }

  @media (max-width: $desktop) {
    @include rem(font-size, 24);
  }

  .utility-menu {
    @include rem(font-size, 16);
    background-color: $gray-dark2;
    color: $white;

    ul {
      height: 60px;
      padding: 0 20px;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: $desktop) {
        height: auto;
        width: 100%;
        flex-flow: column;
      }

      
      li:not(:last-child)::after {
        content: '|';
        margin: 0 10px;
        
        @media (max-width: $desktop) {
          content: '';
        }
      }
      
      li:nth-child(3) {
        margin-left: auto;
        @media (max-width: $desktop) {
          margin-left: initial;
        }
      }

      li:nth-child(2)::after {
        content: "";
      }

    }
    
    &.desktop {
      @media (max-width: $desktop) {
        display: none;
      }
    } 

    &.mobile {
      @media (max-width: $desktop) {
        display: flex;
      }
    }
  }

  .header-wrapper {
    background: linear-gradient($gray-light 90%, transparent);
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    height: 185px;

    @media (max-width: 1600px) {
      height: 140px;
    }

    @media (max-width: 1340px) {
      height: 115px;
    }
    
    @media (max-width: $desktop) {
      flex-flow: row wrap;
      background-color: $gray-light;
      height: auto;
      gap: 0;
    }

  }

  .header-sticky {
    @media (min-width: $desktop) {
      position: fixed;
      width: 100%;
      top: 0;
    }
  }

  .header-logo {
    background-color: $white;
    border-radius: 0 0 25px 0;
    box-shadow: 1px 1px $shadow;
    padding: 26px 20px 15px 25px;
    height: 143px;
    max-width: 366px;
    align-content: center;

    img {
      height: auto;
      max-width: 309px;
      width: 100%;
    }
    
    @media (max-width: 1600px) {
      max-width: 225px;
      height: 115px;
    }
    
    @media (max-width: 1340px) {
      padding: 10px;
      height: 90px;
    }

    @media (max-width: $desktop) {
      height: 70px;
      
      img {
        max-width: 159px;
      }
    }

  }

  .mobile-menu-btn {
    fill: $purple;

    @media (max-width: $desktop) {
      margin: 24px 25px;
    }
  }

  .mobile-close-btn {
    fill: $purple;
    margin: 24px 25px;
  }

  .show {
    display: none;

    @media (max-width: $desktop) {
      display: inline-block;
    }
  }

  .hide {
    display: none;
  }

  nav {
    flex-basis: 70%;
    
    @media (max-width: 1600px) {
      text-align: center;
      flex-basis: 65%;
    }

    @media (max-width: $desktop) {
      display: none;
      z-index: 9;
      background-color: $gray-light;
      flex-basis: 100%;
    }
    
  }

  .nav-link.has-children {
    position: relative;

    a {
      @media (max-width: $desktop) {
        max-width: 300px;
      }
    }

    &:has(.active) .header-link {
      color: $purple;
      font-weight: 500;
    }
  }

  .landing-nav {
    display: flex;
    flex-flow: row nowrap;
    @media (min-width: 1025px) {
      padding-left: 113px;
    }

    .nav-list {
      @media (min-width: 1025px) {
        display: flex;
        justify-content: flex-start;
        text-align: left;
      }
    }
  }

  .nav-list {
    height: 100%;
    flex-flow: row;
    align-items: center;
    column-gap: 50px;
    text-align: center;

    @media (max-width: $desktop) {
      flex-flow: column;
      padding-bottom: 40px;
      margin-left: 0;
    }

    .active {
      color: $purple;
      font-weight: 500;
    }
    
    .purple-bold {
      @media (max-width: $desktop) {
        color: $purple;
        font-weight: 500;
      }
    }

    li {
      @media (max-width: $desktop) {
        width: 100%;
      }
    }
  }

  .has-children {
    a.lvl-1{
      @media (max-width: $desktop) {
          padding-left: 35px;
      }
    }
    
    &.menu-open {
      background-color: $white;
      text-align: center;

      @media (min-width: $desktop) {
        background-color: transparent;
      }

      .expand-button {
        transform: rotate(0deg);
        height: 18px;
        width: auto;

        @media (min-width: $desktop) {
          height: 21px;
        }
      }

      .child-menu-list {
        display: inline-block;

        @media (min-width: 1025px) {
              position: absolute;
              z-index: 2;
              width: 365px;
              left: 0;
              margin-top: 28px;
              background: $white;
              border-radius: 20px;
              @include shadow(rgba(0,0,0,0.16));
            }
      }
    }
    padding: 15px 0;

    .center-btn {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-flow: row nowrap;
    }
  }

  .has-children-header {
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  .expand-button {
    transform: rotate(-90deg);
    right: 40px;
    margin-left: 12px;
    height: 18px;
    width: auto;

    @media (min-width: $desktop) {
      right: 0;
      cursor: pointer;
      height: 21px;
    }
  }

  .child-menu-list {
    @include rem(font-size, 18);
    display: none;

    li {
      margin: 33px auto;
      font-size: 18px;

      @media (min-width: $desktop) {
        margin: 26px auto;
        font-size: 20px;
      }
    }
  }

  .drug-logo {
    padding: 21px 48px 35px 0;
    text-align: right;
    
    @media (max-width: 1340px) {
      padding: 17px 15px 35px 0;
    }

    @media (max-width: $desktop) {
      flex-basis: 100%;
      text-align: center;
      padding: 0 0 40px 0;
    }

    img {
      object-fit: contain;
      max-width: 230px;
      width: 100%;

      @media (max-width: 1600px) {
        max-width: 145px;
      }

      @media (max-width: 1340px) {
        max-width: 130px
      }
      
      @media (max-width: 1024px) {
        max-width: 186px
      }

    }

    &--desktop {
      display: flex;
      align-content: center;

      @media (max-width: $desktop) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media (max-width: $desktop) {
        display: block;
      }
    }

  }

  .mobile-additional-links {
    @include rem(font-size, 16);
    display: block;
    background-color: $gray-dark2;
    color: $white;
    padding: 15px 50px;

    li {
      margin: 15px 0;
    }

    @media (min-width: $desktop) {
      display: none;
      
    }
  }

  .menu-open {
    .nav-list {
      height: auto;
    }
  }
}

.landing-page {
  .header-wrapper {
    z-index: 9;
  }
}

.nav-link {
  min-width: 134px;

  @media (max-width: 1339px) {
    min-width: 100px;
  }
}

.crenessity_pt {
  #nav .center-btn {
    max-width: 180px;
    margin: auto;

    @media (max-width: 1540px) {
      max-width: 120px;
    }
    
    @media (max-width: 1339px) {
      max-width: 100px;
    }
    
    @media (max-width: $desktop) {
      max-width: 100%;
    }
  }

  .has-children.menu-open {
    .child-menu-list {
      left: -5.4vw;

      @media (min-width: 1541px) {
        left: -4.2vw;
      }

      @media (max-width: 1540px) {
        left: -8vw;
      }

      @media (max-width: 1339px) {
        left: -10vw;
      }
    }
  }
}


.crenessity_hcp {
  #nav {
    @media (min-width: 1025px) {
        display: flex !important;
        padding-left: 25px;
        padding-right: 25px;
    }

    @media (max-width: 1399px) {
      padding-left: 0;
    }
    
    @media (max-width: 1080px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    a {
      display: block;
    } 
  }
  .nav-list {
    column-gap: 20px;

    .nav-link {
      min-width: 134px;
      max-width: 180px;
      width: 100%;

      @media (max-width: 1700px) {
        max-width: 140px;
      }
      
      @media (max-width: 1399px) {
        min-width: 125px;
      }
      
      @media (max-width: 1339px) {
        min-width: 115px;
      }
      
      @media (max-width: 1200px) {
        min-width: 100px;
      }
      
      @media (max-width: 1080px) {
        min-width: 90px;
      }
      
      @media (max-width: $desktop) {
        min-width: 100%;
      }
    }
  }

  .has-children.menu-open {
    .child-menu-list {
      left: -4.3vw;

      @media (max-width: 1699px) {
        left: -7.2vw;
      }
      
      @media (max-width: 1699px) {
        left: -8.9vw;
      }
      
      @media (max-width: 1200px) {
        left: -11.2vw;
      }
    }
  }
}
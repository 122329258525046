// Helper Classes
.bg-contain {
    background-size: contain;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-left-top {
    background-position: left top;  
}

.bg-center {
    background-position: center;
}

.bg-right-top {
    background-position: right top;
}

.yellow-border {
    border: 1px solid $yellow;
    border-radius: 25px;

    @media (min-width: $tablet) {
        max-width: 800px;
    }
}

.spacer {
    height: 0.5rem;

    @media (min-width: $tablet) {
        height: 1.5rem;
    }
}

.terms-and-conditions {
    display: none;
    
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    overflow-y: hidden;
    z-index: 9;

    justify-content: center;

    .tc-header {
        color: $navy;
        font-size: 40px;

        @media (max-width: $tablet) {
            font-size: 32px;
        }
    }

    .tc-modal {
        border-radius: 25px;
        border: #FDB515 solid 3px;
        background-color: $white;
        margin: 36px;
        padding: 0 28px 100px 28px;
        position: relative;
        z-index: 9;
        height: 90%;
        
        flex-flow: column;
        justify-content: space-between;
        align-self: center;

        @media (max-width: $tablet) {
            margin: 15px;
            padding: 0 15px 0 15px;
        }
    }

    .modal-content {
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        max-height: 100%;
        overflow-y: scroll;

        p {
            flex-basis: 100%;
            margin-bottom: 30px;
            font-size: 20px;

            @media (max-width: $tablet) {
                font-size: 18px;
            }
        }

        .modal-link {
            text-decoration: underline;
        
            &.btn {
                color: $white;
                background-color: $purple;
                padding: 20px 50px;
                border-radius: 17px;
                text-decoration: none;
                
                @media (max-width: $tablet) {
                    margin-top: 45px;
                }
            }
        }
    }

    .modal-close {
        margin: 35px 15px auto auto;
        flex-basis: 50px;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .9;
        background-color: $white;
    }

}

.terms-modal-link {
    cursor: pointer;
}

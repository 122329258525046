.financial-assistance {
    .fineprint-box-block {
        margin: auto;
    }

    #copay-card-headers,
    #savings-program-headers,
    #copay-savings-card-headers,
    #quickstart-headers,
    #quickstart-program-headers,
    #pas-headers,
    #pap-headers {
        h2 {
            font-size: 50px;
            line-height: 55px;
        }
    }

    #pas-content,
    #pap-content {

        .static-bg-image.desktop {
    
            @media (max-width: 1720px) {
                left: auto;
                bottom: 0;
                right: 0;
                text-align: right;
            }
    
            img {
                object-position: right bottom;
                
                @media (max-width: 1720px) {
                    width: 50%;
                }
            }
        }
    }
}
body {
    font-family: $poppins;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    -mox-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    color: $gray-dark;
}

.serendipity-text {
    font-family: $serendipity;
    line-height: 1.2em;
    font-weight: 400;
    display: block;
    @include rem(font-size, 62);

    @media (min-width: $desktop) {
        @include rem(font-size, 88);
    }
}

.h1-serendipity {
    @include rem(font-size, 32);
    line-height: calc(38/32);
    font-weight: 600;

    @media (min-width: $desktop) {
        @include rem(font-size, 55);
        line-height: calc(60/55);
    }
}

.h1 {
    @include rem(font-size, 38);
    line-height: calc(48/38);
    font-weight: 600;

    @media (min-width: $desktop) {
        @include rem(font-size, 50);
        line-height: calc(55/50);
    }
}

// used in hero interior block as h1
.h2 {
    @include rem(font-size, 38);
    line-height: calc(48/38);
    font-weight: 600;

    @media (min-width: $desktop) {
        @include rem(font-size, 46);
        line-height: calc(55/46);
    }
}


.h3 {
    @include rem(font-size, 32);
    line-height: calc(38/32);
    font-weight: 600;

    @media (min-width: $desktop) {
        @include rem(font-size, 40);
        line-height: calc(55/40);
    }
}

.h4 {
    @include rem(font-size, 28);
    line-height: calc(42/28);
    font-weight: 600;

    @media (min-width: $desktop) {
        @include rem(font-size, 40);
        line-height: calc(60/40);
    }
}

.h5 {
    @include rem(font-size, 21);
    line-height: calc(31/21);
    font-weight: 600;

    @media (min-width: $desktop) {
        @include rem(font-size, 30);
        line-height: calc(46/30);
    }
}

.h6 {
    @include rem(font-size, 21);
    line-height: calc(31/21);
    font-weight: 600;

    @media (min-width: $desktop) {
        @include rem(font-size, 26);
        line-height: calc(36/26);
    }
}


.font-32 {
    @include rem(font-size, 32);
    line-height: calc(38/21);
}

.font-30 {
    @include rem(font-size, 30);
    line-height: calc(36/30);
}


.font-24 {
    @include rem(font-size, 20);
    line-height: calc(30/20);

    @media (min-width: $desktop) {
        @include rem(font-size, 24);
        line-height: calc(40/24);
    }
}

.font-21 {
    @include rem(font-size, 21);
    line-height: calc(30/21);

    @media (min-width: $desktop) {}
}

.font-20 {
    @include rem(font-size, 20);
    line-height: calc(30/20);
}

// ---- Font Color Classes
$text-colors-list: (
    'purple': $purple,
    'pink': $pink,
    'gray': $shadow,
    'gray-dark': $gray-dark,
    'gray-medium': $gray-medium,
    'green': $green,
    'navy': $navy,
    'teal': $teal,
    'teal-light': $teal-light,
    'orange': $orange,
    'red': $red,
    'white': $white,
);

@each $key, $val in $text-colors-list {
    .text-#{$key} {
        color: $val;
    }
}

// ---- Font Weight Classes
$weight-list: (
    'light': 300,
    'regular': 400,
    'medium': 500,
    'semibold': 600,
    'bold': 700,
);

@each $key, $val in $weight-list {
    .weight-#{$key} {
        font-weight: $val;
    }
}


// ---- Text Alignment Classes
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}


a,
button {
    transition: .25s ease;
}


ol {
    list-style-type: none;
    counter-reset: numbered-list;
    padding-left: 1em;

    li {
        position: relative;

        &::before {
            position: absolute;
            left: -1em;
            text-align: right;
            counter-increment: numbered-list;
            content: counters(numbered-list, "") ".";
        }
    }
}
.image-text-cards-grid-block {

    .cards {
        display: flex;
        flex-direction: column;
        gap:28px;
    }
    
    .card {
        border-radius: 25px;
        padding: 16px 16px 30px;
        @include shadow($shadow);

        .image {
            position: relative;
            width: 100%;
            height: 0;
            padding-top: 100%;
            border-radius: 23px;
            overflow: hidden;
            margin-bottom: 25px;
            img {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center top;
            }
        }
    }

    @media (min-width: $tablet) {
        .cards {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0;
            margin: 0 -22px;
            row-gap: 44px;

            .card {
                min-height: 384px;
                width: calc(50% - 44px);
                margin: 0 22px;
                display: flex;
                padding: 16px 30px 16px 16px;
            
                .image {
                    width: 42.8%;
                    padding: 0;
                    height: auto;
                }

                .text {
                    width: 58.2%;
                    padding-left: 30px;
                    padding-bottom: 30px;
                }
            }
        }
    }
}
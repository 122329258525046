.call-to-action-block {
    position: relative;
    padding: 45px 0 40px;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .rich-text {
        // @include rem(max-width, 700);
        margin: 0 auto;

        *+h1,
        *+h2,
        *+h3,
        *+h4,
        *+ul,
        *+ol,
        *:not(hr)+p {
            margin-top: .1rem;
        }
    }

    .btn {
        margin-top: 20px;
    }

    @media (max-width: 1023px) {
        padding: 47px 0 60px;
        .font-30, .font-32 {
            @include rem(font-size, 21);
            line-height: calc(30/21);
        }
    }
}
.icon-text-block {

    .item {
        text-align: center;

        + .item {
            margin-top: 3rem;
        }
    }

    .icon-wrapper {
        margin: 0 auto .8rem;
    }

    img {
        max-width: 229px !important;
        width: 100%;
        height: auto;
        margin: 0 auto;
    } 

    .text {
        text-align: left;
        @extend .font-24;

        b, strong {
            font-weight: 600;
        }
    }

    &.yellow-border {
        padding: 20px;
    }

    @media (min-width: $desktop) {

        &:not(.yellow-border) {
            .icon-wrapper {
                width: 20%;
                min-width: 80px;
                display: flex;
                flex-direction: column;
            }
    
            img {
                max-width: 100px;
                margin: 0 auto;
                width: auto;
                max-height: 100px;
            }
            .text {
                width: 80%;
            }
        }

        .item {
            display: flex;

            + .item {
                margin-top: 2rem;
            }
        }

        .icon-wrapper {
            flex-shrink: 0;
            margin: 0;
        }

        img {
            max-width: 192px !important;
        }
        
        .text {
            padding-left: 25px;
        }

        &.yellow-border {
            padding: 30px;

            .item {
                margin-left: 0;
                align-items: center;
            }
        }
    }
}

.number-list-block {
    max-width: 1110px;
    width: 62%;
    @media (max-width: $desktop) {
        width: 100%;
    }
    ol {
        counter-reset: item;
        padding-left: 0 !important;


        li {
            &::before {
                position: absolute;
                @include rem(font-size, 200);
                content: counter(item) " ";
                counter-increment: item;
                font-family: $serendipity;
                color: $purple;
                left: 0;
                max-width: 100px;
                width: 100%;
                text-align: center;
                top: -.2em;

                @media (max-width: $desktop) {
                    position: relative;
                    max-width: none;
                    left: auto;
                    width: auto;
                    top: initial;
                }
            }

            &:nth-child(3) {
                &::before {
                    top: -.1em;
                    @media (max-width: $desktop) {
                        top: initial
                    }
                }
            }

        }

        .list-border {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding-left: 7.5rem;

            @media (max-width: $desktop) {
                flex-flow: column;
                padding-left: unset;
            }

            p {
                padding: 20px;
                margin: 10px 0;
                border: $purple-light solid 3px;
                border-radius: 25px;
            }
        }

    }
}
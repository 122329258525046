footer {
  background-color: $gray-light;
  color: $gray-dark2;
  min-height: 121px;
  bottom: 0;

  @media (max-width: 960px) {
    text-align: center;
    padding: 51px 0;
  }

  .active {
    color: $gray-dark;
    text-decoration: underline;
  }

  .footer-wrapper {
    flex-flow: row;
    justify-content: space-between;
    padding: 16px 0;

    @media (max-width: 1200px) {
      flex-flow: column;
      align-items: center;
    }

    .footer-left {
      flex-flow: column;
      justify-content: center;

      .footer-list li {
        @media (max-width: $desktop) {
          margin: 10px auto;
        }
      }

      @media (max-width: 1200px) {
        align-items: center;
      }
    }

    ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      column-gap: 40px;

      @media (max-width: 960px) {
        flex-flow: column;
      }

    }

    .footer-copywright {
      @include rem(font-size, 12);
    }
  }
}
// screenreader helpers
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.sr-only-focusable {
    &:active, &:focus {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        clip: auto;
    }
}


// focus styles
a, button, input, textarea, select {
    &:focus-visible {
        outline-color:#58aeff;
        outline-offset: 2px;
        outline-style: solid;
        outline-width: 2px;
    }
}
$tablet: 768px;
$desktop: 1024px;


.desktop-show {
    display: none !important;
    @media (min-width: $desktop) {
        display: block !important;
    }
}

.desktop-hide {
    @media (min-width: $desktop) {
        display: none !important;
    }
}

.show-tablet-down {
    display: none;

    @media (max-width: $tablet) {
        display: block;
    }
}

.show-tablet-up {
    display: block;

    @media (max-width: $tablet) {
        display: none;
    }
}
// Grid / Flex Utility Classes

.grid {
    display: grid;
    grid-gap: 20px;

    @media (min-width: $desktop) {
        grid-gap: 40px;
        @for $i from 1 through 6 {
            &.grid-#{$i} {
                grid-template-columns: repeat($i, minmax(0, #{$i}fr));
            } 
        }
    }

    @media (min-width: 1200px) {
        grid-gap: 70px;
        @for $i from 1 through 6 {
            &.grid-#{$i} {
                grid-template-columns: repeat($i, minmax(0, #{$i}fr));
            } 
        }
    }
}


.flex {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &.justify-center {
        justify-content: center;
    }
    
    @media (min-width: $desktop) {
        @for $i from 1 through 6 {
           .flex-row-#{$i} {
                width: #{calc(100/$i) + '%'};
            } 
        }
    }
}



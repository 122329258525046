#hcp-cards-one-column {
    .image-text-cards-grid-block .cards {
        flex-direction: column;
    }
    
    .image-text-cards-grid-block .cards .card {
        width: 100%;

        @media (min-width: $desktop) {
            width: 95%;
        }
    }
}
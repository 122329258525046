.supplemental-navigation-block {
    padding: 45px 0;

    .flex {
        gap: 35px;
        justify-content: center;
        flex-flow: row wrap;
    }

    li {
        .btn {
            @extend .box-shadow-gray;
        } 
    }

    @media (max-width: $tablet) {
        .btn-wrapper, li, .btn {
            display: block;
            width: 100%;
        }
    }

    @media (min-width: $tablet) {
        padding: 60px 0;

        .flex {
            gap: 45px;
            width: 100%;
            max-width: 1142px;
            margin: 0 auto;
        }
        
        li  {
           flex-grow: 1;
           .btn {
            width: 100%;
           }
        }
    }
}


.block-one_column, .block-two_columns, .block-three_columns, .block-four_columns {
   +  .supplemental-navigation-block {
        margin-top: 25px;
   }
}
body.prescribing-crenessity {
    #number-list {
        .static-bg-image.desktop {
            width: 36%;
            height: 70%;
            top: auto;
            left: auto;
            bottom: 0;
            right: 0;

            .bg-image {
                object-position: right bottom;
            }
        }

        .static-bg-image.mobile {
            margin-left: -25px;
            margin-right: -25px;
        }

        .btn {
            margin-top: 40px;

            &.btn-medium {
                padding: 20px 10px;
            }
        }
        
        .number-list-block ~ .btn-wrapper {
            display: inline-block;
            margin: 0 12px;
    
            a {
                width: 318px;
            }

            @media (max-width: 1300px) {
                display: block;
                margin-left: 125px !important;
            }

            @media (max-width: 1023px) {
                display: flex;
                justify-content: center;
                margin-left: auto !important;
                margin: 0;
            }
        }

        .number-list-block+.btn-wrapper {
            margin-left: 105px;
        }
        
        .number-list-block+.btn-wrapper+.btn-wrapper {
            @media (max-width: 1300px) {
                display: block;
                margin-left: 105px;
            }

            @media (max-width: 1023px) {
                display: flex;
                justify-content: center;
            }
        }

        .number-list-block ~ .btn-wrapper:last-child {
            @media (max-width: 1900px) {
                display: block;
                margin-left: 105px;
            } 

            @media (max-width: 1023px) {
                display: flex;
                justify-content: center;
            }
        }
    }

    .number-list-block {
        width: 100%;

        @media (max-width: 1900px) {
            width: 62%;
        }
        
        @media (max-width: 1024px) {
            width: 100%;
        }
    }

}
$max-container: 1730px;

.container {
    padding: 0 25px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $max-container;

    &.container-padding-16 {
        padding: 0 16px;
    }

}

.container-margin {
    margin: auto 37px;

    @media (min-width: $tablet) {
        margin: auto 25px;
    }
}

body.pharmacy,
body.pantherx {
    #pantherx-hero {
        .inner {
            max-width: 60%;

            @media (max-width: $desktop) {
                max-width: 100%;
            }
        }
    }
    .static-bg-image.desktop {
        @media (max-width: 1708px) {
            width: 70%;
            height: 70%;
            top: auto;
            left: auto;
            bottom: 0;
            right: 0;
        }
        
        @media (max-width: 1440px) {
            width: 35%;
        }
        
        .bg-image {
            object-position: right bottom;
        }
    }
}
.callout-card {
    border-radius: 25px;
    overflow: hidden;

    .callout-card-inner {
        padding: 16px;
    }

    .callout-card-logos {
        display: flex;
        flex-flow: row wrap;
        max-width: 209px;
        height: auto;
        margin: 0 auto 40px;

        .logo {
            flex-basis: 50%;
            flex-grow: 1;
        }
    }

    .callout-card-copy {
        flex-basis: 100%;
        font-size: 16px;
        color: $black;
        text-align: center;
        padding-top: 20px;
    }

    .buttons {
        display: flex;
        flex-flow: column;
        gap: 16px;
    }

    .btn {
        width: 100%;
    }

    @media (min-width: $tablet) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media (min-width: $desktop) {

        .callout-card-inner {
            padding-right: 40px;
            display: flex;
            justify-content: space-between;
        }

        .callout-card-logos {
            margin: 0 16px;
            max-width: 100%;
            align-self: center;
        }

        .buttons {
            min-width: 200px;
            flex-shrink: 0;
            width: 56%;
            padding-left: 18px;
            margin-left: auto;
        }

        .two-logos {

            .buttons {
                width: 40%;
            }

            .logo {
                @media (max-width: $desktop) {
                    max-width: 35%;
                    margin: 20px auto;
                }
            }
        }
    }

    @media (max-width: 1199px) {
        .callout-card-logos {
            flex-grow: 1;
            justify-content: center;
        }
    }
    
    @media (max-width: $desktop) {
        .callout-card-logos {
            max-width: 90%;
        }
    }
}
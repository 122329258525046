body {
  >.rich-text {
    @extend .container;
  }
}

.rich-text {

  *+h1,
  *+h2,
  *+h3,
  *+h4,
  *+ul,
  *+ol,
  *:not(hr)+p {
    margin-top: .8rem;
  }

  hr {
    width: 100%;
  }

  h1:not(:has(.serendipity-text)) {
    @extend .h1;
    margin-bottom: .8rem;
  }

  h1:has(.serendipity-text) {
    all: unset !important;
    @include rem(font-size, 32);
    line-height: calc(38/32) !important;

    .serendipity-text {
      margin-bottom: .6rem;
    }

    + h2 {
      font-size: 2rem;
      line-height: 1.1875;
      margin-bottom: .6rem;
      margin-top: 0;
    }

    @media (min-width: $desktop) {
      + h2 {
        font-size: 3.4375rem !important;
        line-height: 1.0909090909 !important;
      }
    }

    @media (min-width: $desktop) {
      margin-bottom: 0 !important;
      .serendipity-text {
        margin-bottom: -1.35rem;
        margin-left: -.2rem;
      }
    }
  }

  h2 {
    @extend .h3;
  }

  h3 {
    @extend .h4;
  }

  h4 {
    @extend .h5;
  }

  h5 {
    @extend .h6;
  }

  a {
    color: $purple;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }


  p:not(.font-32):not(.font-30):not(.font-24):not(.font-21):not(.font-20) {
    @extend .font-20;
  }

  p {
    margin-top: .4rem;
  }

  // used for fineprint
  small {
    font-size: 0.875rem;
    display: block;
    line-height: 1.25rem;

    &:has(sup) {
      sup {
        float: left;
        line-height: 1em;
        top: 0;
      }
    }
  }

  b,
  strong {
    font-weight: 600;
  }

  ol,
  ul {
    li {
      position: relative;
      padding-left: 1.2rem;
      @include rem(font-size, 20);
      line-height: calc(40/20);

      +li {
        margin-top: .8rem;
      }
    }
  }

  ul {
    li {
      &::before {
        content: '\2022';
        color: $orange;
        position: absolute;
        display: inline-block;
        @include rem(font-size, 28);
        left: 0;
        top: -.25em;
      }
    }
  }

  .brackets {
    all: unset;
    position: relative;

    &::before, &::after {
      color: $pink;
      font-weight: 600;
      display: inline;
    }
    &::before {
      content: '[';
      padding-right: .1em;
    }
    &::after {
      content: ']';
      padding-left: .1em;
    }
  }
}
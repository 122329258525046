// Define reusable color variables here
$black: #000;
$white: #fff;

$purple-light: #B295C6;
$purple: #652C8E;

$teal: #0BBAB4;
$teal-light: #86DCD9;

$green: #2B5F51;

$navy: #0F3050;

$bright-pink: #FC00B8;
$pink: #D50054;
$pink-dark: #993366;

$red: #D50054;

$gray-dark: #6B6B6B;
$gray-dark2: #707070;
$gray-medium: #B5B5B5;
$gray-light: #F7F7F7;

$shadow: rgba($black, .16);

$yellow: #FDB515;

$orange: #FF6C00;

$colors-list: (
    'purple': $purple,
    'pink': $pink,
    'gray': $shadow,
    'gray-light': $gray-light,
    'gray-dark': $gray-dark,
    'gray-medium': $gray-medium,
    'green': $green,
    'teal': $teal,
    'navy': $navy,
    'shadow': $shadow
);

@each $key, $val in $colors-list {
    .box-shadow-#{$key} {
        @include shadow($val);
    }

    .bg-#{$key} {
        background-color: $val;
    }
}

.bg-gradient, .bg-pink, .bg-purple, .bg-gray-dark, .bg-green {
   color: $white;
}
.fineprint-box-block {
    border: 1px solid $yellow;
    border-radius: 25px;
    padding: 36px 32px;

    .font-21 {
        line-height: calc(40/21);
    }

    img {
        display: inline-block;
        max-width: 100%;
        width: auto !important;
        height: auto !important;
        margin-bottom: 20px;
    }

    a {
        font-weight: 500;
        text-decoration: underline;
    }

    @media (min-width: $tablet) {
        padding: 36px;
        max-width: 840px;
    }
    
}
.resources {
    #cards-one-column {
        @media (max-width: $desktop) {
            display: none;
        }

        .image-text-cards-grid-block .cards {
            flex-direction: column;
        }
        
        .image-text-cards-grid-block .cards .card {
            width: 100%;

            @media (min-width: $desktop) {
                width: 95%;
            }
        }
    }

    #cards-one-column-mobile {
        display: none;

        @media (max-width: $desktop) {
            display: block;
        }

        .image-text-cards-grid-block .cards .card {
            width: 100%;

            @media (min-width: $desktop) {
                width: 95%;
            }
        }
    }

    h2 {
        @include rem(font-size, 20);
        margin-top: 10px;
    }
}

.theme-crenessity {
    $primary-color: $purple;

    a:hover {
        color: $primary-color;
    }

    .box-shadow {
        @include shadow($primary-color);
    }

    a.box-shadow:hover {
        background-color: $primary-color;
        color: $white;
    }

    .callout-card {
        @include shadow($primary-color);
    }

    .supplemental-navigation-block {
        li:not(.inactive) {
            .btn {
                @extend .box-shadow
            } 
        }
    }
}